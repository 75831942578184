

const confirmed = function (value,[target], ctx) {
  
  if (value === ctx.form[target]) {
    return true;
  }
  return 'E-mails não são iguais';
}

export default confirmed
