import axios from "axios";

var url = 'http://localhost/dev/skopos-varejista-api/public/'

if(window.location.href.includes('energia.app.br')){
    url = 'https://energia.app.br/skopos-varejista-api/public/'
}

if(window.location.href.includes('-dev')){
    url = 'http://192.168.0.118:8084/severVarejista-api/public/'
}

if(window.location.href.includes('localhost')){
    url = 'http://localhost/dev/skopos-varejista-api/public/'
}

const instance = axios.create({
    baseURL: url,
    headers: {
        'Authorization': localStorage.getItem('tokenSkopos') ?? null,
        'ngrok-skip-browser-warning' : true
    }
});

export default instance