import required from "./Required"
import email from "./Email"
import ConfirmeEmail from "./ConfirmeEmail"
import ConfirmePassword from "./ConfirmePassword"
import Cpf from "./Cpf"

const rules = {
    'required' : required,
    'email' : email,
    'ConfirmeEmail' : ConfirmeEmail,
    'ConfirmePassword' : ConfirmePassword,
    'Cpf' : Cpf,
}

export default rules
