import { createApp } from 'vue';
import App from './App.vue';
import { store } from './store';
import Routes from './routes';
import 'bootstrap';
import Notifications from '@kyvg/vue3-notification';
// import VueSmartWidget from "vue-smart-widget"

import AllRules from './rules/RulesValidate';
import { defineRule } from 'vee-validate';
import vSelect from 'vue-select';

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

const app = createApp(App);

app.component('v-select', vSelect);

app.config.globalProperties.$JQuery = require('jquery');

app.config.globalProperties.$isAccess = function (value) {
  var usuarioAcessos = atob(
    localStorage.getItem('acessos').replaceAll('"', '')
  );

  var regex = new RegExp('master', 'i');
  var acesso = usuarioAcessos.match(regex);

  if (acesso) {
    return true;
  }

  regex = new RegExp('ADM', 'i');
  acesso = usuarioAcessos.match(regex);

  if (acesso) {
    return true;
  }

  if (value == null || value == '') {
    return false;
  }

  regex = new RegExp(value, 'i');
  acesso = usuarioAcessos.match(regex);
  return acesso;
};

app.use(Routes);
app.use(store);
app.use(Notifications);

app.mount('#app');
